import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"
import ImageGallery from "react-image-gallery"
import { ResizableBox } from "react-resizable"

import {
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"

import IconClose from "../../svg/IconClose"

import * as draggableStyles from "./draggable.module.scss"
import * as imageGalleryStyles from "./imageGallery.module.scss"

if (typeof window !== "undefined") {
  gsap.registerPlugin(Draggable)
}

export default function Drawings({ toggleTab, visibility, imgArr }) {
  const state = React.useContext(GlobalStateContext)

  const draggableRef = useRef(null)
  const imgRef = useRef(null)
  const indexRef = useRef(null)

  const [hoverResizeHandleState, setHoverResizeHandleState] = useState(false)


  useEffect(() => {
    let maxContainerWidth = (window.innerWidth / 100) * 96
    // let maxContainerHeight = (window.innerHeight / 100) * 76 - 29

    let initialTabWidth = (window.innerWidth / 100) * 36
    // let initialTabHeight = (window.innerHeight / 100) * 50
    let positionX = Math.floor(
      Math.random() * (maxContainerWidth - initialTabWidth)
    )
    // let positionY = Math.floor(
    //   Math.random() * (maxContainerHeight - initialTabHeight - 30)
    // )
    gsap.set(draggableRef.current, { x: positionX, y: window.innerHeight })
    draggableRef.current.style.zIndex = "1000";
  }, [state.resizeWidth, state.resizeHeight])

  useEffect(() => {
    let draggables = []
    if (draggables[0] === undefined) {
      draggables = Draggable.create(draggableRef.current, {
        type: "x,y",
        edgeResistance: 0.8
      })
    }

    draggables[0].disable()

    if (visibility) {
      if (hoverResizeHandleState === null) {
        setTimeout(() => {
          draggables[0].enable()
          draggables[0].vars.bounds = "#container"
        }, 400);
      } else if (hoverResizeHandleState === true) {
        draggables[0].disable()

      } else {
        draggables[0].enable()
        draggables[0].vars.bounds = "#container"
      }
    } else {
      draggables[0].disable()
      draggables[0].vars.bounds = null
      setHoverResizeHandleState(null)
    }

  }, [visibility, hoverResizeHandleState])

  useEffect(() => {
    let currentZ = parseInt(draggableRef.current.style.zIndex)
    draggableRef.current.parentNode.childNodes.forEach(element => {
      let zIndexOfEl = parseInt(element.style.zIndex)
      if (currentZ < zIndexOfEl) {
        currentZ = zIndexOfEl
      }
    })
    let newZ = currentZ + 10
    let newZ2 = currentZ + 11
    draggableRef.current.style.zIndex = newZ
    Draggable.zIndex = newZ2
  }, [visibility])

  useEffect(() => {
    if (visibility) {
      gsap.to(draggableRef.current, {
        y: "15%",
        opacity: "100%",
        duration: 0.4
      });
    } else {
      gsap.to(draggableRef.current, {
        y: window.innerHeight,
        opacity: "80%",
        duration: 0.4
      });
    }
  }, [visibility, state.resizeWidth, state.resizeHeight])

  let images = []
  imgArr.forEach(element => {
    images.push({ original: element.file.url })
  })

  function renderLeftNav(onClick, disabled) {
    if (imgRef.current !== null && indexRef.current !== null) {
      indexRef.current.innerText = imgRef.current.getCurrentIndex() + 1
    }
    return (
      <button
        className={imageGalleryStyles.leftNav}
        disabled={disabled}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
        </svg>
      </button>
    )
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        className={imageGalleryStyles.rightNav}
        disabled={disabled}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
        </svg>
      </button>
    )
  }

  let initialTabWidth = 600
  let initialTabHeight = 400

  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    initialTabWidth = (window.innerWidth / 100) * 36
    initialTabHeight = (window.innerHeight / 100) * 50
  }

  return (
    <>
      <div ref={draggableRef} className={draggableStyles.draggable}>
        <div className={draggableStyles.center}>
          <div className={draggableStyles.container}>
            <div className={draggableStyles.header}>
              <div>&nbsp;</div>
              <div>
                Image <span ref={indexRef}>1</span> of {imgArr.length}
              </div>
              <div className={draggableStyles.close}>
                <button onClick={toggleTab}>
                  <IconClose />
                </button>
              </div>
            </div>
            <ResizableBox
              width={initialTabWidth}
              height={initialTabHeight}
              minConstraints={[400, 400]}
              handle={
                <span
                  className={draggableStyles.reactResizableHandle}
                  onMouseOver={() => setHoverResizeHandleState(true)}
                  onMouseLeave={() => setHoverResizeHandleState(false)}
                  onFocus={() => setHoverResizeHandleState(true)}
                  aria-hidden="true"
                  aria-disabled="true"
                ></span>
              }
            >
              <div className={imageGalleryStyles.content}>
                <ImageGallery
                  ref={imgRef}
                  items={images}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  disableKeyDown={true}
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                />
              </div>
            </ResizableBox>
          </div>
        </div>
      </div>

      <div className={draggableStyles.fixed}>
        {visibility ? (
          <div className={draggableStyles.center}>
            <div className={draggableStyles.container}>
              <div className={imageGalleryStyles.content}>
                <ImageGallery
                  items={images}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  disableKeyDown={false}
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
