import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import {
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"

import Layout from "../components/Layout"
import Feature from "../components/main/feature/Feature"
import Description from "../components/main/tabs/Description"
import Gallery from "../components/main/tabs/Gallery"
import Drawings from "../components/main/tabs/Drawings"
import Precedents from "../components/main/tabs/Precedents"
import Panorama from "../components/main/tabs/Panorama"

import * as projectStyles from "./project.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulProjects(slug: { eq: $slug }) {
      title
      featureImage {
        file {
          url
        }
      }
      featureImageLoader {
        featureImageLoader
      }
      description {
        raw
      }
      gallery {
        file {
          url
        }
      }
      drawings {
        file {
          url
        }
      }
      precedents {
        file {
          url
        }
      }
      panorama
    }
  }
`


const Project = ({ data }) => {
  // Data check
  let content = data.contentfulProjects

  // Initialise content status
  let contentStatusDescription, contentStatusGallery, contentStatusDrawings, contentStatusPrecedents, contentStatusPanorama

  // If content type exists then set content status/availability to true
  content.description && (contentStatusDescription = true)
  content.gallery && (contentStatusGallery = true)
  content.drawings && (contentStatusDrawings = true)
  content.precedents && (contentStatusPrecedents = true)
  content.panorama && (contentStatusPanorama = true)

  // // Adds first image of each content type to an array
  // let pictures = []

  // if (contentStatusGallery) {
  //   pictures.push(content.gallery[0].file.url)
  // }

  // if (contentStatusDrawings) {
  //   pictures.push(content.drawings[0].file.url)
  // }

  // if (contentStatusPrecedents) {
  //   pictures.push(content.precedents[0].file.url)
  // }

  // // Loads each image in pictures array for faster load times.
  // useEffect(() => {
  //   pictures.forEach(picture => {
  //     const img = new Image()
  //     img.src = picture
  //   })
  // })


  let descriptionContent

  if (contentStatusDescription) {
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => { }
      }
    }

    const description = content.description
    descriptionContent = renderRichText(description, options)
  }


  // Tab Visibility
  const [visibilityDescription, stateDescription] = useState(false)
  const [visibilityGallery, stateGallery] = useState(false)
  const [visibilityDrawings, stateDrawings] = useState(false)
  const [visibilityPrecedents, statePrecedents] = useState(false)
  const [visibilityPanorama, statePanorama] = useState(false)


  const toggleDescription = () => {
    if (document.documentElement.clientWidth > 950) {
      stateDescription(!visibilityDescription)
    } else {
      stateGallery(false)
      stateDrawings(false)
      statePrecedents(false)
      statePanorama(false)
      stateDescription(true)
    }
  }

  const toggleGallery = () => {
    if (document.documentElement.clientWidth > 950) {
      stateGallery(!visibilityGallery)
    } else {
      stateDescription(false)
      stateDrawings(false)
      statePrecedents(false)
      statePanorama(false)
      stateGallery(true)
    }
  }

  const toggleDrawings = () => {
    if (document.documentElement.clientWidth > 950) {
      stateDrawings(!visibilityDrawings)
    } else {
      stateDescription(false)
      stateGallery(false)
      statePrecedents(false)
      statePanorama(false)
      stateDrawings(true)
    }
  }

  const togglePrecedents = () => {
    if (document.documentElement.clientWidth > 950) {
      statePrecedents(!visibilityPrecedents)
    } else {
      stateDescription(false)
      stateGallery(false)
      stateDrawings(false)
      statePanorama(false)
      statePrecedents(true)
    }
  }

  const togglePanorama = () => {
    if (document.documentElement.clientWidth > 950) {
      statePanorama(!visibilityPanorama)
    } else {
      stateDescription(false)
      stateGallery(false)
      stateDrawings(false)
      statePrecedents(false)
      statePanorama(true)
    }
  }

  // toggles description tab for mobile devices
  useEffect(() => {
    if (document.documentElement.clientWidth < 951) {
      stateDescription(() => true)
    }
  }, [])

  const resetTabs = () => {
    stateDescription(false)
    stateGallery(false)
    stateDrawings(false)
    statePrecedents(false)
    statePanorama(false)
  }

  const dispatch = React.useContext(GlobalDispatchContext)
  const isBrowser = typeof window !== "undefined"
  // if (isBrowser) {
  //   window.addEventListener('resize', () => {
  //     resetTabs();
  //     dispatch({ type: "RESIZE_TRIGGERED" })
  //   });
  // }

  const projectContent = true


  return (
    <Layout
      projectContent={projectContent}
      toggleDescription={toggleDescription}
      toggleGallery={toggleGallery}
      toggleDrawings={toggleDrawings}
      togglePrecedents={togglePrecedents}
      togglePanorama={togglePanorama}
      contentStatusDescription={contentStatusDescription}
      contentStatusGallery={contentStatusGallery}
      contentStatusDrawings={contentStatusDrawings}
      contentStatusPrecedents={contentStatusPrecedents}
      contentStatusPanorama={contentStatusPanorama}
      visibilityDescription={visibilityDescription}
      visibilityGallery={visibilityGallery}
      visibilityDrawings={visibilityDrawings}
      visibilityPrecedents={visibilityPrecedents}
      visibilityPanorama={visibilityPanorama}
      resetTabs={resetTabs}
      title={content.title}
    >
      <Helmet>
        <title>Makan - {content.title}</title>
        <meta name="description" content="Makan is an experimental design collective, established in 2016 by Steav Nissan, Darren Dharmadasa and Frank Burridge. Having diverse educational backgrounds, it was created to maintain a platform from which to consolidate respective professional experience in practice, research and teaching, post-graduation. We primarily operate through competitions to interrogate and test various spatial conditions, both individually and in collaboration with external practices." />
      </Helmet>
      <div className={projectStyles.center}>
        <Feature
          imageURL={content.featureImage.file.url}
          svgPath={content.featureImageLoader.featureImageLoader}
          visibilityDescription={visibilityDescription}
          visibilityGallery={visibilityGallery}
          visibilityDrawings={visibilityDrawings}
          visibilityPrecedents={visibilityPrecedents}
          visibilityPanorama={visibilityPanorama}
        />
        <div id="container" className={projectStyles.container}>
          <div className={projectStyles.container_relative}>
            {contentStatusDescription &&
              <Description
                visibility={visibilityDescription}
                toggleTab={toggleDescription}
                descriptionContent={descriptionContent}
              />
            }
            {contentStatusGallery &&
              <Gallery
                visibility={visibilityGallery}
                toggleTab={toggleGallery}
                imgArr={content.gallery}
              />
            }
            {contentStatusDrawings &&
              <Drawings
                visibility={visibilityDrawings}
                toggleTab={toggleDrawings}
                imgArr={content.drawings}
              />
            }
            {contentStatusPrecedents &&
              <Precedents
                visibility={visibilityPrecedents}
                toggleTab={togglePrecedents}
                imgArr={content.precedents}
              />
            }
            {contentStatusPanorama &&
              <Panorama
                visibility={visibilityPanorama}
                toggleTab={togglePanorama}
                content={content.panorama}
              />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Project
