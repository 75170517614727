// extracted by mini-css-extract-plugin
export var center = "draggable-module--center--S4WWR";
export var close = "draggable-module--close--SIcrp";
export var container = "draggable-module--container--Yb6Cb";
export var draggable = "draggable-module--draggable--0YUEI";
export var fixed = "draggable-module--fixed--AjKlS";
export var header = "draggable-module--header--GbhNk";
export var iframe = "draggable-module--iframe--wSwZ8";
export var iframeCover = "draggable-module--iframeCover--PlUUl";
export var iframeWrapper = "draggable-module--iframeWrapper--pRp+T";
export var reactResizableHandle = "draggable-module--reactResizableHandle--sDcKu";