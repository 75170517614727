import React, { useEffect } from "react"
import { GlobalStateContext } from "../../../context/GlobalContextProvider"
import * as featureStyles from "./feature.module.scss"

export default function Feature({
  imageURL,
  svgPath,
  visibilityDescription,
  visibilityGallery,
  visibilityDrawings,
  visibilityPrecedents,
  visibilityPanorama,
}) {
  const state = React.useContext(GlobalStateContext)

  useEffect(() => {
    let featureImage = document.querySelector("#feature_image")
    featureImage.style.backgroundImage = `url(${imageURL})`
    let svg = document.querySelector("#svg")
    let path = document.querySelector("#path")
    let length = path.getTotalLength()
    path.style.transition = path.style.WebkitTransition = "none"
    path.style.strokeDasharray = length + " " + length
    path.style.strokeDashoffset = length
    path.getBoundingClientRect()
    path.style.strokeWidth = "2"
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 1.5s ease-in-out"
    path.style.strokeDashoffset = "0"

    setTimeout(() => {
      svg.style.opacity = "0%"
      featureImage.style.opacity = "100%"
      setTimeout(() => {
        svg.style.display = "none"
      }, 500)
    }, 1500)
  }, [imageURL])

  useEffect(() => {
    let cover = document.querySelector("#cover")
    cover.style.backgroundColor = state.color

    if (
      visibilityDescription ||
      visibilityGallery ||
      visibilityDrawings ||
      visibilityPrecedents ||
      visibilityPanorama
    ) {
      cover.style.display = "block"
      cover.style.opacity = "80%"
    } else {
      cover.style.opacity = "0%"
      setTimeout(() => {
        if (cover.style.opacity === "0") {
          cover.style.display = "none"
        }
      }, 400);
    }
  })

  let handleMouseMove = e => {
    const el = document.getElementById("feature_image")
    const d = el.getBoundingClientRect()
    let x = e.clientX - (d.left + Math.floor(d.width / 2))
    let y = e.clientY - (d.top + Math.floor(d.height / 2))
    x = x - x * 2
    y = y - y * 2
    document.documentElement.style.setProperty("--scale", 2)
    document.documentElement.style.setProperty("--x", x / 2 + "px")

    document.documentElement.style.setProperty("--y", y / 2 + "px")
  }

  let handleMouseLeave = () => {
    document.documentElement.style.setProperty("--scale", 1)
    document.documentElement.style.setProperty("--x", 0)
    document.documentElement.style.setProperty("--y", 0)
  }

  return (
    <div id="feature" className={featureStyles.container}>
      <div id="cover" className={featureStyles.cover}></div>
      <div
        id="feature_image"
        aria-hidden="true"
        className={featureStyles.feature_image}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      ></div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={featureStyles.svg_layer}
        id="svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <clipPath id="_clipPath_x5PS1HVPCPE5VwLRBf4AUcUwVD3dURSB">
            <rect width="1920" height="1080" />
          </clipPath>
        </defs>
        <g clipPath="url(#_clipPath_x5PS1HVPCPE5VwLRBf4AUcUwVD3dURSB)">
          <path
            id="path"
            className="color_cycle_path"
            d={svgPath}
            fill="none"
            vectorEffect="non-scaling-stroke"
            strokeWidth="0"
            stroke="#000000"
            strokeLinejoin="miter"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
        </g>
      </svg>
    </div>
  )
}
