import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"
import { ResizableBox } from "react-resizable"

import {
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"

import IconClose from "../../svg/IconClose"

import * as draggableStyles from "./draggable.module.scss"
import * as imageGalleryStyles from "./imageGallery.module.scss"

if (typeof window !== "undefined") {
  gsap.registerPlugin(Draggable)
}



export default function Panorama({ toggleTab, visibility, content }) {
  const state = React.useContext(GlobalStateContext)

  const draggableRef = useRef(null)

  const [hoverResizeHandleState, setHoverResizeHandleState] = useState(false)
  const [iframeCover, setIframeCover] = useState(false)

  useEffect(() => {
    let maxContainerWidth = (window.innerWidth / 100) * 96
    // let maxContainerHeight = (window.innerHeight / 100) * 76 - 29

    let initialTabWidth = (window.innerWidth / 100) * 36
    // let initialTabHeight = (window.innerHeight / 100) * 50
    let positionX = Math.floor(
      Math.random() * (maxContainerWidth - initialTabWidth)
    )
    // let positionY = Math.floor(
    //   Math.random() * (maxContainerHeight - initialTabHeight - 30)
    // )
    gsap.set(draggableRef.current, { x: positionX, y: window.innerHeight })
    draggableRef.current.style.zIndex = "1000";
  }, [state.resizeWidth, state.resizeHeight])

  useEffect(() => {
    let draggables = []
    if (draggables[0] === undefined) {
      draggables = Draggable.create(draggableRef.current, {
        type: "x,y",
        edgeResistance: 0.8
      })
    }

    draggables[0].disable()

    if (visibility) {
      if (hoverResizeHandleState === null) {
        setTimeout(() => {
          draggables[0].enable()
          draggables[0].vars.bounds = "#container"
        }, 400);
      } else if (hoverResizeHandleState === true) {
        draggables[0].disable()

      } else {
        draggables[0].enable()
        draggables[0].vars.bounds = "#container"
      }
    } else {
      draggables[0].disable()
      draggables[0].vars.bounds = null
      setHoverResizeHandleState(null)
    }

  }, [visibility, hoverResizeHandleState])

  useEffect(() => {
    let currentZ = parseInt(draggableRef.current.style.zIndex)
    draggableRef.current.parentNode.childNodes.forEach(element => {
      let zIndexOfEl = parseInt(element.style.zIndex)
      if (currentZ < zIndexOfEl) {
        currentZ = zIndexOfEl
      }
    })
    let newZ = currentZ + 10
    let newZ2 = currentZ + 11
    draggableRef.current.style.zIndex = newZ
    Draggable.zIndex = newZ2
  }, [visibility])

  useEffect(() => {
    if (visibility) {
      gsap.to(draggableRef.current, {
        y: "25%",
        opacity: "100%",
        duration: 0.4
      });
    } else {
      gsap.to(draggableRef.current, {
        y: window.innerHeight,
        opacity: "80%",
        duration: 0.4
      });
    }
  }, [visibility, state.resizeWidth, state.resizeHeight])

  useEffect(() => {
    const intervalId = setInterval(() => {
      let array = []
      let largest = null
      if (visibility) {
        draggableRef.current.parentNode.childNodes.forEach(element => {
          if (element.style.zIndex) {
            array.push(parseInt(element.style.zIndex))
          }
        })
        for (var i = 0; i < array.length; i++) {
          if (largest < array[i]) {
            largest = array[i];
          }
        }

        if (parseInt(draggableRef.current.style.zIndex) !== largest) {
          setIframeCover(true)
        }
      }
    }, 500);

    return (() => {
      clearInterval(intervalId);
    })
  })


  let initialTabWidth = 600
  let initialTabHeight = 400

  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    initialTabWidth = (window.innerWidth / 100) * 36
    initialTabHeight = (window.innerHeight / 100) * 50
  }

  return (
    <>
      <div ref={draggableRef} className={draggableStyles.draggable}>
        <div className={draggableStyles.center}>
          <div className={draggableStyles.container}>
            <div className={draggableStyles.header}>
              <div>&nbsp;</div>
              <div>
              </div>
              <div className={draggableStyles.close}>
                <button onClick={toggleTab}>
                  <IconClose />
                </button>
              </div>
            </div>
            <ResizableBox
              width={initialTabWidth}
              height={initialTabHeight}
              minConstraints={[400, 400]}
              handle={
                <span
                  className={draggableStyles.reactResizableHandle}
                  onMouseOver={() => setHoverResizeHandleState(true)}
                  onMouseLeave={() => setHoverResizeHandleState(false)}
                  onFocus={() => setHoverResizeHandleState(true)}
                  aria-hidden="true"
                  aria-disabled="true"
                ></span>
              }
            >
              <div className={draggableStyles.iframeWrapper}>
                {iframeCover &&
                  <div onPointerUp={() => { setIframeCover(false) }} className={draggableStyles.iframeCover}></div>
                }
                <iframe className={draggableStyles.iframe}
                  title={content.title} frameBorder="0"
                  allow="fullscreen; gyroscope; accelerometer; magnetometer; execution-while-out-of-viewport; execution-while-not-rendered;"
                  allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true"
                  src={content}
                >
                </iframe>
              </div>
            </ResizableBox>
          </div>
        </div>
      </div>

      <div className={draggableStyles.fixed}>
        {visibility ? (
          <div className={draggableStyles.center}>
            <div className={draggableStyles.container}>
              <div className={imageGalleryStyles.content}>
                <iframe className={draggableStyles.iframe}
                  title={content.title} frameBorder="0"
                  allow="fullscreen; gyroscope; accelerometer; magnetometer; execution-while-out-of-viewport; execution-while-not-rendered;"
                  allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true"
                  src={content}
                >
                </iframe>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
